import React from 'react'
import Image from './image'
import { Link } from 'gatsby'

const Project = props => {
  if (props.align === 'right') {
    return (
      <div className={`project section-columns ${props.align}`}>
        <div
          className={`project-image ${props.project}`}
          data-aos="fade-up"
          data-aos-delay="50"
        >
          <Image
            filename={props.backgroundImage}
            class="background-image"
            alt={props.project}
          />
          <div data-aos="fade-up" data-aos-delay="130">
            <Image filename={props.image} />
          </div>
        </div>
        <div className="project-content" data-aos="fade-up" data-aos-delay="50">
          <small>{props.year}</small>
          <h3 data-aos="fade-up" data-aos-delay="20">
            {props.title}
          </h3>
          <p data-aos="fade-up" data-aos-delay="20">
            {props.text}
          </p>
          {props.hasLink === 'true' && (
            <Link to={props.link} className="btn">
              {props.linkText}
            </Link>
          )}
          {props.hasStudy === 'false' && (
            <p>
              <span className="tag">Case study coming soon</span>
            </p>
          )}
        </div>
      </div>
    )
  } else if (props.align === 'left') {
    return (
      <div className={`project section-columns ${props.align}`}>
        <div className="project-content" data-aos="fade-up" data-aos-delay="50">
          <small>{props.year}</small>
          <h3 data-aos="fade-up" data-aos-delay="20">
            {props.title}
          </h3>
          <p data-aos="fade-up" data-aos-delay="20">
            {props.text}
          </p>
          {props.hasLink === 'true' && (
            <Link to={props.link} className="btn">
              {props.linkText}
            </Link>
          )}
          {props.hasStudy === 'false' && (
            <p>
              <span className="tag">Case study coming soon</span>
            </p>
          )}
        </div>
        <div
          className={`project-image ${props.project}`}
          data-aos="fade-up"
          data-aos-delay="50"
        >
          <Image
            filename={props.backgroundImage}
            class="background-image"
            alt={props.project}
          />
          <div data-aos="fade-up" data-aos-delay="130">
            <Image filename={props.image} />
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className={`project section-columns ${props.align}`}>
      <div className="project-content">
        <small data-aos="fade-up" data-aos-delay="120">
          {props.year}
        </small>
        <h3 data-aos="fade-up" data-aos-delay="120">
          {props.title}
        </h3>
        <p data-aos="fade-up" data-aos-delay="20">
          {props.text}
        </p>
        <p>
          <span className="tag" data-aos="fade-up" data-aos-delay="120">
            Case study coming soon
          </span>
        </p>
      </div>
      <div
        className={`project-image ${props.project}`}
        data-aos="fade-up"
        data-aos-delay="50"
      >
        <Image
          filename={props.backgroundImage}
          class="background-image"
          alt={props.project}
        />
        <Image filename={props.image} />
      </div>
    </div>
  )
}

Project.defaultProps = {
  align: 'left',
  hasLink: false,
  year: 2019,
  title: 'Project Title',
  text: 'Still typing the text...',
  link: 'https://dannygie.be',
  linkText: 'Website',
  image: '',
}

export default Project
