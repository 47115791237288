import React from 'react'
import QuoteIcon from "../images/quote.svg"
import SVGImage from "./svgImage"

const QuoteComponent = (props) => {


    return (
       <div className="quote-card" data-aos="fade-up" data-aos-delay="20">
          <div className={`quote-head ${props.brand}`}>
            <div data-aos="fade-in" data-aos-delay="0">
              
              <SVGImage name={props.brand} fill="#fff" width="171" />
            </div>
            <div className="quote-icon">
              <img src={QuoteIcon} alt="Quote"/>
            </div>
          </div>
          <blockquote>
            <p>
            {props.quotetext}
            </p>
            <p className="author">{props.author}</p>
          </blockquote>
        </div>
    )
  }


export default QuoteComponent